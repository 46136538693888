.App {
  font-size: 16px;
}

a {
  text-decoration: none !important;
  /* color: #007bff; */
}

a:hover {
  text-decoration: underline !important;
  /* color: #0056b3; */
}