.navbar-nav {
    flex-wrap: wrap;
}

.dropdown {
    margin: .5em 2em .5em 0;
}

.dropdown:after {
    content: '/';
    color: #6c757d;
    position: absolute;
    margin: -2em 0 0 2.5em;
}

.dropdown-toggle:after {
    content: none !important;
    border: 0 !important;
}