.parallax {
    width: 100%;
    min-height: 400px;
    height: 60vh;
}

.sitename {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 400px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sitename div {
    padding: 30px 40px;
    background: #1d1d1d;
}

.sitename div a {
    font-size: 1.75em;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 4px;
    font-weight: 700;
    color: #fff;
}

.sitename div a:hover {
    color: #007bff;
}

.sitename div hr {
    border: 1px;
    border-top: 1px solid rgba(255,255,255,.5);
    margin: 1em 0;
}

.sitename div p {
    font-size: 1.25rem;
    font-weight: 300;
    color: rgba(255,255,255,.5);
    text-align: center;
}
